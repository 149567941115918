<template>
  <section id="main" class="main">
    <div class="main-promo" :class="{ winter: !summer }">
      <div class="container">
        <div class="main-titles">
          <h1 class="main-title">Апартаменты</h1>
          <h1 class="main-title main-title-orange">#sVidomNaGory</h1>
          <h5 class="main-subtitle">Красная Поляна</h5>
          <a
            href="https://reservationsteps.ru/rooms/index/8fd0fe63-c684-4f99-a522-fc6030425767"
            target="_blank"
            class="btn btn-orange main-button"
          >
            забронировать {{ device == 'mobile' ? ' номер' : '' }}
          </a>
        </div>
        <button
          @click="scrollTo('#map', { offset: -headerHeight })"
          type="button"
          class="main-button-down"
        >
          <img src="@/assets/icon/button-down.svg" />
        </button>
      </div>
    </div>
    <div :style="device == 'mobile' ? 'display: none' : ''" class="main-link">
      <div class="main-link-desc">
        <p>
          Посетите также нашу виллу <span>sVidomNaMore</span> в Хосте, Сочи:
        </p>
        <a
          href="https://svidomnamore.ru/"
          target="_blank"
          class="btn btn-white"
        >
          перейти
        </a>
      </div>
    </div>
    <div :class="device == 'mobile' ? '' : ''" class="main-swiper-wrapper">
      <Swiper
        v-if="isShow"
        :space-between="device == 'mobile' ? 0 : 10"
        :slides-per-view="device == 'mobile' ? 1 : 'auto'"
        :modules="modules"
        :navigation="{
          nextEl: '.main-button-next',
          prevEl: '.main-button-prev',
        }"
        :pagination="{
          el: '.main-pagination',
          clickable: true,
        }"
        @swiper="onSwiper"
        loop
        class="main-swiper"
      >
        <div class="main-swiper-shadow"></div>
        <div class="main-swiper-shadow right"></div>
        <div v-if="device == 'mobile'" class="main-pagination white" />
        <ButtonSwiper
          v-if="device !== 'mobile'"
          class="main-button-prev"
          prev
        />
        <ButtonSwiper v-if="device !== 'mobile'" class="main-button-next" />
        <ButtonSwitch v-if="device == 'mobile'" />
        <SwiperSlide
          v-for="(item, i) of gallery"
          :key="item.name"
          :style="{ width: device == 'mobile' ? '' : uni(item.width) }"
        >
          <img
            v-if="device !== 'mobile'"
            @click="open(i)"
            :src="
              require(`../assets/img/${
                summer ? 'summer-gallery' : 'winter-gallery'
              }/img-${i + 1}${device === 'mobile' ? '-mobile' : ''}.png`)
            "
            class="img-click"
          />
          <img
            v-else
            :src="
              require(`../assets/img/${
                summer ? 'summer-gallery' : 'winter-gallery'
              }/${item.name}.png`)
            "
          />
        </SwiperSlide>
      </Swiper>
    </div>

    <div :style="device == 'mobile' ? '' : 'display: none'" class="main-link">
      <div class="main-link-desc">
        <p>
          Посетите также нашу виллу <span>sVidomNaMore</span> в Хосте, Сочи:
        </p>
        <a
          href="https://svidomnamore.ru/"
          target="_blank"
          class="btn btn-white"
        >
          перейти
        </a>
      </div>
    </div>
    <div v-show="device === 'mobile'" class="widget">
      <div class="left" id="_bn_widget_">
        <a href="https://bnovo.ru/" id="_bnovo_link_" target="_blank">Bnovo</a>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import { mapState, mapMutations } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import { Navigation, Pagination } from 'swiper'
import ButtonSwiper from './ButtonSwiper.vue'
import ButtonSwitch from './ButtonSwitch.vue'
import { scroller } from 'vue-scrollto/src/scrollTo'

export default {
  components: { Swiper, SwiperSlide, ButtonSwiper, ButtonSwitch },
  name: 'SectionMain',
  data() {
    return {
      isShow: true,
      scrollTo: scroller(),
      // важно: нужно указать размер для слайдов, иначе loop некорректно будет отображать расстояние между слайдами
      summer_gallery: [
        {
          width: 237,
        },
        {
          width: 226,
        },
        {
          width: 430,
        },
        {
          width: 369,
        },
        {
          width: 357,
        },
        {
          width: 471,
        },
        {
          width: 236,
        },
      ],
      winter_gallery: [
        {
          width: 237,
        },
        {
          width: 226,
        },
        {
          width: 429,
        },
        {
          width: 357,
        },
        {
          width: 417,
        },
      ],
      summer_gallery_mobile: [
        {
          name: 'img-1-mobile',
        },
        {
          name: 'img-2-mobile',
        },
        {
          name: 'img-3-mobile',
        },
        {
          name: 'img-4-mobile',
        },
        {
          name: 'img-5-mobile',
        },
        {
          name: 'img-6-mobile',
        },
        {
          name: 'img-7-mobile',
        },
        {
          name: 'img-8-mobile',
        },
      ],
      winter_gallery_mobile: [
        {
          name: 'img-1-mobile',
        },
        {
          name: 'img-2-mobile',
        },
        {
          name: 'img-3-mobile',
        },
        {
          name: 'img-4-mobile',
        },
        {
          name: 'img-5-mobile',
        },
        {
          name: 'img-6-mobile',
        },
      ],
    }
  },
  watch: {
    // чтобы при переключении сезона, порядок был с 1го слайда
    summer() {
      if (this.device !== 'mobile') {
        this.isShow = false
        setTimeout(() => {
          this.isShow = true
        }, 100)
      }
    },
  },
  computed: {
    ...mapState(['summer', 'headerHeight']),
    gallery() {
      if (this.summer)
        return this.device == 'mobile'
          ? this.summer_gallery_mobile
          : this.summer_gallery
      return this.device == 'mobile'
        ? this.winter_gallery_mobile
        : this.winter_gallery
    },
  },
  methods: {
    ...mapMutations(['setFullImagesParams']),
    open(i) {
      this.setFullImagesParams({
        room: this.summer ? 'summer-gallery' : 'winter-gallery',
        count: this.gallery.length,
        initialSlide: i,
        isShow: true,
      })
    },
  },
  setup() {
    const swiper = ref(null)

    const onSwiper = (sw) => {
      swiper.value = sw
    }

    return {
      onSwiper,
      modules: [Navigation, Pagination],
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  @include desktopXL {
    padding-bottom: uniWXL(20);
  }
  @include desktop {
    padding-bottom: uniW(20);
  }
  &-promo {
    background: url('../assets/img/main-bg-desktop.png') no-repeat;
    background-size: auto 100%;
    background-position-x: right;
    @include desktopXL {
      height: uniWXL(678);
    }
    @include desktop {
      height: uniW(678);
      background: url('../assets/img/main-bg-desktop.png') no-repeat;
      background-size: auto 100%;
      background-position-x: calc(100% + uniW(240));
    }
    @include mobile {
      background: none;
      height: 100%;
      position: absolute;
      width: 100%;
    }
    &.winter {
      background: url('../assets/img/main-bg-desktop-winter.png') no-repeat;
      background-size: auto 100%;
      background-position-x: right;
      @include desktop {
        background: url('../assets/img/main-bg-desktop-winter.png') no-repeat;
        background-size: auto 100%;
        background-position-x: calc(100% + uniW(240));
      }
      @include mobile {
        background: none;
      }
    }
  }
  &-titles {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    width: fit-content;
    @include mobile {
      width: 100%;
      top: uniWM(95);
      z-index: 10;
      transform: translateY(0);
      padding: 0 uniWM(15);
    }
  }
  &-title {
    color: $main-grey;
    width: fit-content;
    @include desktopXL {
      font-size: uniWXL(70);
      line-height: uniWXL(70);
    }
    @include desktop {
      font-size: uniW(70);
      line-height: uniW(70);
    }
    @include mobile {
      color: $white;
      font-size: uniWM(31);
      line-height: uniWM(34);
    }
  }
  &-title-orange {
    color: $orange;
    font-style: italic;
    width: fit-content;
    @include desktopXL {
      transform: translateX(uniWXL(61));
    }
    @include desktop {
      transform: translateX(uniW(61));
    }
    @include mobile {
      transform: translateX(uniWM(28));
    }
  }
  &-subtitle {
    color: $main-grey;
    font-style: italic;
    width: fit-content;
    @include desktopXL {
      transform: translateX(uniWXL(108));
      font-size: uniWXL(24);
      line-height: uniWXL(24);
      margin-top: uniWXL(6);
      margin-bottom: uniWXL(45);
    }
    @include desktop {
      transform: translateX(uniW(108));
      font-size: uniW(24);
      line-height: uniW(24);
      margin-top: uniW(6);
      margin-bottom: uniW(45);
    }
    @include mobile {
      transform: translateX(uniWM(60));
      font-size: uniWM(20);
      line-height: uniWM(20);
      margin-bottom: uniWM(30);
      margin-top: uniWM(5);
      color: $white;
    }
  }

  &-link {
    display: flex;
    background-color: $orange;
    position: relative;

    @include desktopXL {
      height: uniWXL(170);
      margin-bottom: uniWXL(20);
      background-image: url('../assets/img/mountains.svg');
      background-repeat: no-repeat;
      background-position: 35% bottom;
      background-size: contain;
    }
    @include desktop {
      height: uniW(170);
      margin-bottom: uniW(20);
      background-image: url('../assets/img/mountains.svg');
      background-repeat: no-repeat;
      background-position: 30% bottom;
      background-size: contain;
    }
    @include mobile {
      align-items: center;
      flex-direction: column;
      height: uniWM(316);
      background-image: url('../assets/img/mountains-mobile.svg');
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
    }
  }

  &-link-desc {
    @include desktopXL {
      position: absolute;
      transform: translateX(uniW(800)) translateY(uniW(12));
      z-index: 10;
      width: uniWXL(500);
    }
    @include desktop {
      position: absolute;
      transform: translateX(uniW(800)) translateY(uniW(12));
      z-index: 10;
      width: uniW(500);
    }
    @include mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: uniWM(303);
      padding-top: uniWM(20);
    }

    p {
      font-weight: 400;
      margin-bottom: uniWM(15);
      letter-spacing: 5%;

      @include desktopXL {
        font-size: uniWXL(25);
        line-height: uniWXL(31);
        margin-bottom: uniWXL(22);
      }
      @include desktop {
        font-size: uniW(25);
        line-height: uniW(31);
        margin-bottom: uniW(22);
      }
      @include mobile {
        font-size: uniWM(20);
        line-height: uniWM(25);
        text-align: center;
      }

      span {
        font-style: italic;
        font-weight: 700;
      }
    }
  }
  &-button {
    @include desktopXL {
      transform: translateX(uniWXL(108));
    }
    @include desktop {
      transform: translateX(uniW(108));
    }
    @include mobile {
      transform: translateX(uniWM(0));
      display: block;
      width: fit-content;
      margin: 0 auto;
    }
  }
  &-button-down {
    position: absolute;
    bottom: 0;
    transform: translate(-50%);
    @include desktopXL {
      bottom: uniWXL(20);
      width: uniWXL(15);
      left: calc(50% - uniWXL(40));
    }
    @include desktop {
      width: uniW(15);
      left: calc(50% - uniW(30));
    }
    @include mobile {
      display: none;
    }
    img {
      @include desktopXL {
        width: uniWXL(15);
      }
      @include desktop {
        width: uniW(15);
      }
    }
  }
  &-pagination {
    left: 50%;
    transform: translateX(-50%);
    @include mobile {
      bottom: uniWM(20);
    }
  }
  &-swiper {
    &-wrapper {
      @include desktopXL {
        height: uniWXL(314);
      }
      @include desktop {
        height: uniW(314);
      }
      @include mobile {
        height: uniWM(598);
      }
    }
    .swiper-slide {
      img {
        &.img-click {
          cursor: pointer;
        }
        @include desktopXL {
          height: uniWXL(314);
          width: auto;
        }
        @include desktop {
          height: uniW(314);
          width: auto;
        }
        @include mobile {
          width: 100%;
          height: auto;
        }
      }
    }
    &-shadow {
      position: absolute;
      height: 100%;
      top: 0px;
      background: linear-gradient(90deg, #00497e 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.6;
      transform: matrix(1, 0, 0, -1, 0, 0);
      z-index: 1;
      pointer-events: none;
      &.right {
        right: 0;
        transform: rotate(180deg);
      }
      @include desktopXL {
        width: uniWXL(126);
      }
      @include desktop {
        width: uniW(126);
      }
      @include mobile {
        display: none;
      }
    }
  }
  &-button-prev {
    position: absolute;
    z-index: 2;
    @include desktopXL {
      left: uniWXL(20);
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: uniWXL(34);
      height: uniWXL(34);
    }
    @include desktop {
      left: uniW(20);
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      width: uniW(34);
      height: uniW(34);
    }
  }
  &-button-next {
    position: absolute;
    z-index: 2;
    @include desktopXL {
      right: uniWXL(20);
      top: 50%;
      transform: translateY(-50%);
      width: uniWXL(34);
      height: uniWXL(34);
    }
    @include desktop {
      right: uniW(20);
      top: 50%;
      transform: translateY(-50%);
      width: uniW(34);
      height: uniW(34);
    }
  }
  .widget {
    @include desktopXL {
      margin: uniWXL(30) 0 uniWXL(10) 0;
    }
    @include desktop {
      margin: uniW(20) 0 uniW(10) 0;
    }
    @include mobile {
      margin: uniWM(20) 0;
    }
  }
}
</style>
