<template>
  <section class="widget" id="widget">
    <!-- <div class="container"> -->
      <div class="widget-wrapper">
        <div class="widget-main">
          <iframe src="https://yandex.ru/maps-reviews-widget/62789722449?comments"></iframe>
          <a
            href="https://yandex.ru/maps/org/apartamenty_s_vidom_na_gory/62789722449/"
            target="_blank"
            style="
              text-decoration: none;
              color: #b3b3b3;
              font-size: 10px;
              font-family: YS Text, sans-serif;
              padding: 0 20px;
              position: absolute;
              bottom: 8px;
              width: 100%;
              text-align: center;
              left: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              max-height: 14px;
              white-space: nowrap;
              padding: 0 16px;
              box-sizing: border-box;
            "
            >Апартаменты с видом на горы на карте Сочи — Яндекс&nbsp;Карты</a
          >
        </div>
      </div>
    <!-- </div> -->
  </section>
</template>

<script>
export default {
  name: 'SectionWidget',
}
</script>

<style lang="scss" scoped>
.widget {
  @include desktopXL {
    padding: uniWXL(92) 0;
  }

  @include desktop {
    padding: uniW(92) 0;
  }

  @include mobile {
    padding: uniWM(21) 0;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-main {
    width: 100%;
    max-width: 760px;
    height: 800px;
  }

  iframe {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
    
    @include desktop {
      border: 1px solid #e6e6e6;
      border-radius: 8px;
    }

    @include desktopXL {
      border: 1px solid #e6e6e6;
      border-radius: 8px;
    }
  }
}
</style>
